// @ts-ignore -- Unused import
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
// @ts-ignore -- Unused import
import { jsonLightReadStringPropertyValue } from '@msdyn365-commerce/retail-proxy/dist/externals';

/**
 * OrderUploadResponseEntity entity interface.
 */
export interface IOrderUploadResponseEntity {
    RecId: number;
    DisplayProductNumber?: string;
    StringId?: string;
    ManufacturerCode?: string;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * AriModelEntity entity interface.
 */
export interface IAriModelEntity {
    Id: number;
    Name?: string;
    BrandCode?: string;
    Description?: string;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * SupersessionResponseEntity entity interface.
 */
export interface ISupersessionResponseEntity {
    ProductId: number;
    InventLocationId?: string;
    TotalAvailable: number;
    DisplayProductNumber?: string;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * CartPromotionsEntity entity interface.
 */
export interface ICartPromotionsEntity {
    OrderTemplateId: string;
    CouponCode?: string;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * EnhancedProductDescriptionEntity entity interface.
 */
export interface IEnhancedProductDescriptionEntity {
    Product: number;
    Description?: string;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * ContractListEntity entity interface.
 */
export interface IContractListEntity {
    ContractCode?: string;
    Description?: string;
    ContractNotes?: string;
    ContractLaborRate: number;
    ManufacturerCode?: string;
    ContractNumber?: string;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * SearchTextProductMap entity interface.
 */
export interface ISearchTextProductMap {
    SearchText?: string;
    Products?: Entities.SimpleProduct[];
}

/**
 * ManufacturerCodeResponseEntity entity interface.
 */
export interface IManufacturerCodeResponseEntity {
    GDNManufacturerCode?: string;
    Description?: string;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * AriServiceModelExtension entity interface.
 */
export interface IAriServiceModelExtension {
    Description?: string;
    Id: number;
    Name?: string;
    BrandCode?: string;
}

/**
 * AriModelResponseEntity entity interface.
 */
export interface IAriModelResponseEntity {
    TotalCount: number;
    Models?: IAriServiceModelExtension[];
}

/**
 * ManufacturerBrandNameResponseEntity entity interface.
 */
export interface IManufacturerBrandNameResponseEntity {
    Description?: string;
    ManufacturerCode?: string;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * AriServiceModelAssemblySearch entity interface.
 */
export interface IAriServiceModelAssemblySearch {
    Id: number;
    Name?: string;
    ParentId: number;
    ParentName?: string;
    IsParent: boolean;
    ImageUrl?: string;
}

/**
 * AriServicePoint entity interface.
 */
export interface IAriServicePoint {
    X?: string;
    Y?: string;
}

/**
 * AriServiceHotSpot entity interface.
 */
export interface IAriServiceHotSpot {
    Tag?: string;
    TopLeft?: IAriServicePoint;
    BottomRight?: IAriServicePoint;
}

/**
 * AriServiceAssemblyPart entity interface.
 */
export interface IAriServiceAssemblyPart {
    PartId?: string;
    Sku?: string;
    Description?: string;
    Context?: string;
    MSRP: number;
    DealerCost: number;
    IsSuperseded: boolean;
    OrgSku?: string;
    OrgDescription?: string;
    OrgMSRP?: string;
    Qty?: string;
    SortTag?: string;
    Tag?: string;
    NLA: boolean;
}

/**
 * AriServiceAssemblyInformation entity interface.
 */
export interface IAriServiceAssemblyInformation {
    AssemblyId: number;
    ZoomLevel: number;
    HotSpots?: IAriServiceHotSpot[];
    Parts?: IAriServiceAssemblyPart[];
    Name?: string;
    ParentId: number;
    ParentName?: string;
    IsParent: boolean;
    ImageUrl?: string;
}

/**
 * AdvancedNoteEntity entity interface.
 */
export interface IAdvancedNoteEntity {
    RecId: number;
    TextValue?: string;
    ItemId?: string;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * AllowableFreightStatusLine entity interface.
 */
export interface IAllowableFreightStatusLine {
    ItemId?: string;
    RecordId: number;
    StatusType: string;
}

/**
 * AllowableFreightCalculation entity interface.
 */
export interface IAllowableFreightCalculation {
    ThresholdAmountCurrent: number;
    ThresholdAmountTotal: number;
    IsUnitCount: boolean;
    AllowableFreightStatusLine?: IAllowableFreightStatusLine[];
    IsAllowableFreightActive: boolean;
    RawData?: string;
}

/**
 * CourseEntity entity interface.
 */
export interface ICourseEntity {
    GDNCourseFee: number;
    RecId: number;
    Description?: string;
    EndDateTime: Date;
    HRMCourseId?: string;
    LastDateOfSignup: Date;
    MaxAttendees: number;
    StartDateTime: Date;
    DataAreaId?: string;
    CurrentAttendees: number;
    Organizer?: string;
    Location?: string;
    IsValid: boolean;
    CourseStatus: string;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * CourseResponseEntity entity interface.
 */
export interface ICourseResponseEntity {
    Courses?: ICourseEntity[];
    TotalCount: number;
}

/**
 * UpdatedCartEntity entity interface.
 */
export interface IUpdatedCartEntity {
    Cart?: Entities.Cart;
}

/**
 * AddCourseToCartResponse entity interface.
 */
export interface IAddCourseToCartResponse {}

/**
 * CalculateSurchargeResponseEntity entity interface.
 */
export interface ICalculateSurchargeResponseEntity {
    Cart?: Entities.Cart;
}

/**
 * CalculateSurchargeResponse entity interface.
 */
export interface ICalculateSurchargeResponse {}

/**
 * ValidatePoNumberResponse entity interface.
 */
export interface IValidatePoNumberResponse {
    Response?: string;
}

/**
 * GetSalesOrderTrackingNumberResponse entity interface.
 */
export interface IGetSalesOrderTrackingNumberResponse {
    TrackingNumber?: string;
}

/**
 * GetOrderDetailsAdditionalInformationResponse entity interface.
 */
export interface IGetOrderDetailsAdditionalInformationResponse {
    ContainerDetails?: IContainerDetailsEntity[];
    ErrorMessage?: string;
}

/**
 * GetSalesOrdersadditionalDetailsResponse entity interface.
 */
export interface IGetSalesOrdersadditionalDetailsResponse {
    SalesOrderDetails?: ISalesOrderAdditionalDetails[];
}

/**
 * GetGdnInvoiceDetailsResponse entity interface.
 */
export interface IGetGdnInvoiceDetailsResponse {
    InvoiceDetails?: IGdnInvoiceDetailsResponseEntity;
    InvoiceLineDetails?: IGdnInvoiceLineEntity[];
    ShippingContainerInformation?: IContainerDetailsEntity[];
    ErrorMessage?: string;
}

/**
 * GetBackOrderListResponse entity interface.
 */
export interface IGetBackOrderListResponse {
    BackOrderLines?: IBackOrderListEntity[];
    RecordCount?: number;
    AvailableSortOptions?: string[];
    SelectedSort?: string;
    SortOrder?: string;
    Filter?: string;
    Top?: number;
    Skip?: number;
}

/**
 * ContainerLinesEntity entity interface.
 */
export interface IContainerLinesEntity {
    ItemId?: string;
    Quantity?: number;
}

/**
 * ContainerDetailsEntity entity interface.
 */
export interface IContainerDetailsEntity {
    CarrierService?: string;
    ContainerId?: string;
    ContainerLinesList?: IContainerLinesEntity[];
    ModeOfDelivery?: string;
    ShippingCarrier?: string;
    TrackingNumber?: string;
    Weight?: number;
}

/**
 * SalesOrderAdditionalDetails entity interface.
 */
export interface ISalesOrderAdditionalDetails {
    SalesId?: string;
    WebTrackingId?: string;
    PoNumber?: string;
}

/**
 * GdnFreightDetails entity interface.
 */
export interface IGdnFreightDetails {
    FreightCode?: string;
    FreightDescription?: string;
    FreightAmount?: number;
}

/**
 * GdnInvoiceDetailsResponseEntity entity interface.
 */
export interface IGdnInvoiceDetailsResponseEntity {
    SalesOrderNumber?: string;
    CustomerAccount?: string;
    InvoiceNumber?: string;
    PoNumber?: string;
    DeliveryName?: string;
    DeliveryLocation?: string;
    DeliveryAddress?: string;
    InvoiceLocation?: string;
    InvoiceAddress?: string;
    InvoiceDate?: Date;
    ShipVia?: string;
    OrderDate?: Date;
    FreightCode?: string;
    FreightDescription?: string;
    FreightAmount?: number;
    BillToAccount?: string;
    TotalPieces?: number;
    SubTotal?: number;
    Tax?: number;
    InvoiceAmount?: number;
    InvoiceAmountMst?: number;
    AmountDue?: number;
    DueDate?: Date;
    Terms?: string;
    FreightList?: IGdnFreightDetails[];
}

/**
 * GdnInvoiceLineEntity entity interface.
 */
export interface IGdnInvoiceLineEntity {
    ItemRecId?: number;
    ItemNumber?: string;
    ProductId?: number;
    CustItemNumber?: string;
    Description?: string;
    AltDescription?: string;
    CurrencyCode?: string;
    Quantity?: number;
    ListPrice?: number;
    UnitPrice?: number;
    Total?: number;
    LineNum?: number;
}

/**
 * BackOrderListEntity entity interface.
 */
export interface IBackOrderListEntity {
    SalesId?: string;
    PurchaseOrderNumber?: string;
    ManufacturerNumber?: string;
    ItemId?: string;
    Quantity?: number;
    Total?: number;
    ShippingDate?: string;
    DeliveryType?: string;
}

/**
 * SalesReportResponseEntity entity interface.
 */
export interface ISalesReportResponseEntity {
    DidSalesReportComplete: boolean;
    SalesReportMessage?: string;
}

/**
 * PriceByManufacturerResponseEntity entity interface.
 */
export interface IPriceByManufacturerResponseEntity {
    DidPriceByManufacturerComplete: boolean;
    PriceByManufacturerMessage?: string;
}

/**
 * DealerLocationEntity entity interface.
 */
export interface IDealerLocationEntity {
    Name?: string;
    CustomerNumber?: string;
    RecId: number;
    Address?: string;
    ZipCode?: string;
    Latitude: number;
    Longitude: number;
    Distance: number;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * DealerEntity entity interface.
 */
export interface IDealerEntity {
    AccountNum?: string;
    Name?: string;
    RecId: number;
    Address?: string;
    ManufacturerCode?: string;
    ManufacturerName?: string;
    ContractCode?: string;
    ContractDescription?: string;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * EligibilityItem entity interface.
 */
export interface IEligibilityItem {
    ItemId?: string;
    IsEligible?: boolean;
    IsAssorted?: boolean;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * SaveCartPromotionsResponseRS entity interface.
 */
export interface ISaveCartPromotionsResponseRS {
    Status: boolean;
    Message?: string;
}

/**
 * DeleteCartPromotionsByOrderTemplateIdResponseRS entity interface.
 */
export interface IDeleteCartPromotionsByOrderTemplateIdResponseRS {
    Status: boolean;
    Message?: string;
}

/**
 * WhereClause entity interface.
 */
export interface IWhereClause {
    Column?: string;
    Operator?: string;
    Value?: string;
    IsString?: boolean;
    Conjunction?: string;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * TableQueryResponseEntity entity interface.
 */
export interface ITableQueryResponseEntity {
    JsonResults?: string;
    Count?: number;
    IsError?: boolean;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * ItemInventoryLevelProfileEntity entity interface.
 */
export interface IItemInventoryLevelProfileEntity {
    ItemId?: string;
    InventoryLevelProfile?: string;
    Code?: string;
    Label?: string;
    UpperBoundInclusive: number;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * ProfileDataMap entity interface.
 */
export interface IProfileDataMap {
    Profile?: IItemInventoryLevelProfileEntity[];
    Products?: string[];
}

/**
 * InvoicePaymentScheduleEntity entity interface.
 */
export interface IInvoicePaymentScheduleEntity {
    Invoice?: string;
    AmountCur?: number;
    DueDate?: Date;
    AllowableFreightCharge?: number;
    Description?: string;
    SalesId?: string;
    PurchaseOrder?: string;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * SearchCustomerByAccountIdResponse entity interface.
 */
export interface ISearchCustomerByAccountIdResponse {
    BusinessPartnerId?: string;
    AccountNumber?: string;
}

/**
 * ProductCodeEntity entity interface.
 */
export interface IProductCodeEntity {}

/**
 * InvoiceAmountEntity entity interface.
 */
export interface IInvoiceAmountEntity {
    InvoiceId?: string;
    Amount?: number;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * AddInvoicesWithAmountsToCartResponse entity interface.
 */
export interface IAddInvoicesWithAmountsToCartResponse {}

/**
 * ProductCodeEntity entity interface.
 */
export interface IProductCodeEntity {
    ItemId?: string;
    ProductCode?: string;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * GetProductCodesResponse entity interface.
 */
export interface IGetProductCodesResponse {
    ProductCodes?: IProductCodeEntity[];
}

/**
 * OrderUploadResponseEntity entity class.
 */
export class OrderUploadResponseEntityExtensionClass implements IOrderUploadResponseEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public RecId: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public DisplayProductNumber: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public StringId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ManufacturerCode: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.RecId = odataObject.RecId ? parseInt(odataObject.RecId, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DisplayProductNumber = odataObject.DisplayProductNumber;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.StringId = odataObject.StringId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ManufacturerCode = odataObject.ManufacturerCode;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * AriModelEntity entity class.
 */
export class AriModelEntityExtensionClass implements IAriModelEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Id: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Name: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public BrandCode: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Description: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Id = odataObject.Id ? parseInt(odataObject.Id, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Name = odataObject.Name;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.BrandCode = odataObject.BrandCode;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Description = odataObject.Description;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * SupersessionResponseEntity entity class.
 */
export class SupersessionResponseEntityExtensionClass implements ISupersessionResponseEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ProductId: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public InventLocationId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public TotalAvailable: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public DisplayProductNumber: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ProductId = odataObject.ProductId ? parseInt(odataObject.ProductId, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.InventLocationId = odataObject.InventLocationId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.TotalAvailable = odataObject.TotalAvailable ? parseFloat(odataObject.TotalAvailable) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DisplayProductNumber = odataObject.DisplayProductNumber;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * CartPromotionsEntity entity class.
 */
export class CartPromotionsEntityExtensionClass implements ICartPromotionsEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public OrderTemplateId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public CouponCode: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.OrderTemplateId = odataObject.OrderTemplateId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.CouponCode = odataObject.CouponCode;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * EnhancedProductDescriptionEntity entity class.
 */
export class EnhancedProductDescriptionEntityExtensionClass implements IEnhancedProductDescriptionEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Product: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Description: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Product = odataObject.Product ? parseInt(odataObject.Product, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Description = odataObject.Description;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * ContractListEntity entity class.
 */
export class ContractListEntityExtensionClass implements IContractListEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ContractCode: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Description: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ContractNotes: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ContractLaborRate: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ManufacturerCode: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ContractNumber: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ContractCode = odataObject.ContractCode;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Description = odataObject.Description;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ContractNotes = odataObject.ContractNotes;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ContractLaborRate = odataObject.ContractLaborRate ? parseFloat(odataObject.ContractLaborRate) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ManufacturerCode = odataObject.ManufacturerCode;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ContractNumber = odataObject.ContractNumber;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * SearchTextProductMap entity class.
 */
export class SearchTextProductMapExtensionClass implements ISearchTextProductMap {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public SearchText: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Products: Entities.SimpleProduct[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.SearchText = odataObject.SearchText;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Products = undefined;
        if (odataObject.Products) {
            this.Products = [];
            for (var i = 0; i < odataObject.Products.length; i++) {
                if (odataObject.Products[i]) {
                    if (odataObject.Products[i]['@odata.type']) {
                        var className: string = odataObject.Products[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.Products[i] = new EntityClasses[className](odataObject.Products[i]);
                        }
                    } else {
                        this.Products[i] = new EntityClasses.SimpleProductClass(odataObject.Products[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.Products[i] = undefined;
                }
            }
        }
    }
}

/**
 * ManufacturerCodeResponseEntity entity class.
 */
export class ManufacturerCodeResponseEntityExtensionClass implements IManufacturerCodeResponseEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public GDNManufacturerCode: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Description: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.GDNManufacturerCode = odataObject.GDNManufacturerCode;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Description = odataObject.Description;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * AriServiceModelExtension entity class.
 */
export class AriServiceModelExtensionExtensionClass implements IAriServiceModelExtension {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Description: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Id: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Name: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public BrandCode: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Description = odataObject.Description;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Id = odataObject.Id ? parseInt(odataObject.Id, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Name = odataObject.Name;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.BrandCode = odataObject.BrandCode;
    }
}

/**
 * AriModelResponseEntity entity class.
 */
export class AriModelResponseEntityExtensionClass implements IAriModelResponseEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public TotalCount: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Models: IAriServiceModelExtension[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.TotalCount = odataObject.TotalCount ? parseInt(odataObject.TotalCount, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Models = undefined;
        if (odataObject.Models) {
            this.Models = [];
            for (var i = 0; i < odataObject.Models.length; i++) {
                if (odataObject.Models[i]) {
                    if (odataObject.Models[i]['@odata.type']) {
                        var className: string = odataObject.Models[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.Models[i] = new EntityClasses[className](odataObject.Models[i]);
                        }
                    } else {
                        this.Models[i] = new AriServiceModelExtensionExtensionClass(odataObject.Models[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.Models[i] = undefined;
                }
            }
        }
    }
}

/**
 * ManufacturerBrandNameResponseEntity entity class.
 */
export class ManufacturerBrandNameResponseEntityExtensionClass implements IManufacturerBrandNameResponseEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Description: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ManufacturerCode: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Description = odataObject.Description;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ManufacturerCode = odataObject.ManufacturerCode;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * AriServiceModelAssemblySearch entity class.
 */
export class AriServiceModelAssemblySearchExtensionClass implements IAriServiceModelAssemblySearch {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Id: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Name: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ParentId: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ParentName: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public IsParent: boolean;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ImageUrl: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Id = odataObject.Id ? parseInt(odataObject.Id, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Name = odataObject.Name;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ParentId = odataObject.ParentId ? parseInt(odataObject.ParentId, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ParentName = odataObject.ParentName;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.IsParent = odataObject.IsParent;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ImageUrl = odataObject.ImageUrl;
    }
}

/**
 * AriServicePoint entity class.
 */
export class AriServicePointExtensionClass implements IAriServicePoint {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public X: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Y: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.X = odataObject.X;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Y = odataObject.Y;
    }
}

/**
 * AriServiceHotSpot entity class.
 */
export class AriServiceHotSpotExtensionClass implements IAriServiceHotSpot {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Tag: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public TopLeft: IAriServicePoint;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public BottomRight: IAriServicePoint;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Tag = odataObject.Tag;

        if (odataObject.TopLeft === null) {
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TopLeft = undefined;
        } else if (odataObject.TopLeft['@odata.type'] == null) {
            this.TopLeft = new AriServicePointExtensionClass(odataObject.TopLeft);
        } else {
            var className: string = odataObject.TopLeft['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
            if (EntityClasses[className]) {
                this.TopLeft = new EntityClasses[className](odataObject.TopLeft);
            }
        }

        if (odataObject.BottomRight === null) {
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.BottomRight = undefined;
        } else if (odataObject.BottomRight['@odata.type'] == null) {
            this.BottomRight = new AriServicePointExtensionClass(odataObject.BottomRight);
        } else {
            var className: string = odataObject.BottomRight['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
            if (EntityClasses[className]) {
                this.BottomRight = new EntityClasses[className](odataObject.BottomRight);
            }
        }
    }
}

/**
 * AriServiceAssemblyPart entity class.
 */
export class AriServiceAssemblyPartExtensionClass implements IAriServiceAssemblyPart {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public PartId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Sku: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Description: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Context: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public MSRP: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public DealerCost: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public IsSuperseded: boolean;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public OrgSku: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public OrgDescription: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public OrgMSRP: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Qty: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public SortTag: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Tag: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public NLA: boolean;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.PartId = odataObject.PartId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Sku = odataObject.Sku;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Description = odataObject.Description;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Context = odataObject.Context;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.MSRP = odataObject.MSRP ? parseFloat(odataObject.MSRP) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DealerCost = odataObject.DealerCost ? parseFloat(odataObject.DealerCost) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.IsSuperseded = odataObject.IsSuperseded;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.OrgSku = odataObject.OrgSku;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.OrgDescription = odataObject.OrgDescription;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.OrgMSRP = odataObject.OrgMSRP;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Qty = odataObject.Qty;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.SortTag = odataObject.SortTag;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Tag = odataObject.Tag;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.NLA = odataObject.NLA;
    }
}

/**
 * AriServiceAssemblyInformation entity class.
 */
export class AriServiceAssemblyInformationExtensionClass implements IAriServiceAssemblyInformation {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public AssemblyId: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ZoomLevel: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public HotSpots: IAriServiceHotSpot[];

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Parts: IAriServiceAssemblyPart[];

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Name: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ParentId: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ParentName: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public IsParent: boolean;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ImageUrl: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.AssemblyId = odataObject.AssemblyId ? parseInt(odataObject.AssemblyId, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ZoomLevel = odataObject.ZoomLevel;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.HotSpots = undefined;
        if (odataObject.HotSpots) {
            this.HotSpots = [];
            for (var i = 0; i < odataObject.HotSpots.length; i++) {
                if (odataObject.HotSpots[i]) {
                    if (odataObject.HotSpots[i]['@odata.type']) {
                        var className: string = odataObject.HotSpots[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.HotSpots[i] = new EntityClasses[className](odataObject.HotSpots[i]);
                        }
                    } else {
                        this.HotSpots[i] = new AriServiceHotSpotExtensionClass(odataObject.HotSpots[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.HotSpots[i] = undefined;
                }
            }
        }

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Parts = undefined;
        if (odataObject.Parts) {
            this.Parts = [];
            for (var i = 0; i < odataObject.Parts.length; i++) {
                if (odataObject.Parts[i]) {
                    if (odataObject.Parts[i]['@odata.type']) {
                        var className: string = odataObject.Parts[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.Parts[i] = new EntityClasses[className](odataObject.Parts[i]);
                        }
                    } else {
                        this.Parts[i] = new AriServiceAssemblyPartExtensionClass(odataObject.Parts[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.Parts[i] = undefined;
                }
            }
        }

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Name = odataObject.Name;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ParentId = odataObject.ParentId ? parseInt(odataObject.ParentId, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ParentName = odataObject.ParentName;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.IsParent = odataObject.IsParent;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ImageUrl = odataObject.ImageUrl;
    }
}

/**
 * AdvancedNoteEntity entity class.
 */
export class AdvancedNoteEntityExtensionClass implements IAdvancedNoteEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public RecId: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public TextValue: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ItemId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.RecId = odataObject.RecId ? parseInt(odataObject.RecId, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.TextValue = odataObject.TextValue;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ItemId = odataObject.ItemId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * AllowableFreightStatusLine entity class.
 */
export class AllowableFreightStatusLineExtensionClass implements IAllowableFreightStatusLine {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ItemId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public RecordId: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public StatusType: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ItemId = odataObject.ItemId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.RecordId = odataObject.RecordId ? parseInt(odataObject.RecordId, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.StatusType = odataObject.StatusType;
    }
}

/**
 * AllowableFreightCalculation entity class.
 */
export class AllowableFreightCalculationExtensionClass implements IAllowableFreightCalculation {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ThresholdAmountCurrent: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ThresholdAmountTotal: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public IsUnitCount: boolean;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public AllowableFreightStatusLine: IAllowableFreightStatusLine[];

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public IsAllowableFreightActive: boolean;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public RawData: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ThresholdAmountCurrent = odataObject.ThresholdAmountCurrent ? parseFloat(odataObject.ThresholdAmountCurrent) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ThresholdAmountTotal = odataObject.ThresholdAmountTotal ? parseFloat(odataObject.ThresholdAmountTotal) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.IsUnitCount = odataObject.IsUnitCount;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.AllowableFreightStatusLine = undefined;
        if (odataObject.AllowableFreightStatusLine) {
            this.AllowableFreightStatusLine = [];
            for (var i = 0; i < odataObject.AllowableFreightStatusLine.length; i++) {
                if (odataObject.AllowableFreightStatusLine[i]) {
                    if (odataObject.AllowableFreightStatusLine[i]['@odata.type']) {
                        var className: string = odataObject.AllowableFreightStatusLine[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.AllowableFreightStatusLine[i] = new EntityClasses[className](odataObject.AllowableFreightStatusLine[i]);
                        }
                    } else {
                        this.AllowableFreightStatusLine[i] = new AllowableFreightStatusLineExtensionClass(
                            odataObject.AllowableFreightStatusLine[i]
                        );
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.AllowableFreightStatusLine[i] = undefined;
                }
            }
        }

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.IsAllowableFreightActive = odataObject.IsAllowableFreightActive;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.RawData = odataObject.RawData;
    }
}

/**
 * CourseEntity entity class.
 */
export class CourseEntityExtensionClass implements ICourseEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public GDNCourseFee: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public RecId: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Description: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public EndDateTime: Date;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public HRMCourseId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public LastDateOfSignup: Date;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public MaxAttendees: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public StartDateTime: Date;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public DataAreaId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public CurrentAttendees: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Organizer: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Location: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public IsValid: boolean;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public CourseStatus: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.GDNCourseFee = odataObject.GDNCourseFee ? parseFloat(odataObject.GDNCourseFee) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.RecId = odataObject.RecId ? parseInt(odataObject.RecId, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Description = odataObject.Description;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.EndDateTime =
            odataObject.EndDateTime instanceof Date
                ? odataObject.EndDateTime
                : odataObject.EndDateTime
                ? jsonLightReadStringPropertyValue(odataObject.EndDateTime, 'Edm.DateTimeOffset', false)
                : undefined;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.HRMCourseId = odataObject.HRMCourseId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.LastDateOfSignup =
            odataObject.LastDateOfSignup instanceof Date
                ? odataObject.LastDateOfSignup
                : odataObject.LastDateOfSignup
                ? jsonLightReadStringPropertyValue(odataObject.LastDateOfSignup, 'Edm.DateTimeOffset', false)
                : undefined;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.MaxAttendees = odataObject.MaxAttendees;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.StartDateTime =
            odataObject.StartDateTime instanceof Date
                ? odataObject.StartDateTime
                : odataObject.StartDateTime
                ? jsonLightReadStringPropertyValue(odataObject.StartDateTime, 'Edm.DateTimeOffset', false)
                : undefined;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DataAreaId = odataObject.DataAreaId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.CurrentAttendees = odataObject.CurrentAttendees ? parseInt(odataObject.CurrentAttendees, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Organizer = odataObject.Organizer;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Location = odataObject.Location;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.IsValid = odataObject.IsValid;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.CourseStatus = odataObject.CourseStatus;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * CourseResponseEntity entity class.
 */
export class CourseResponseEntityExtensionClass implements ICourseResponseEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Courses: ICourseEntity[];

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public TotalCount: number;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Courses = undefined;
        if (odataObject.Courses) {
            this.Courses = [];
            for (var i = 0; i < odataObject.Courses.length; i++) {
                if (odataObject.Courses[i]) {
                    if (odataObject.Courses[i]['@odata.type']) {
                        var className: string = odataObject.Courses[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.Courses[i] = new EntityClasses[className](odataObject.Courses[i]);
                        }
                    } else {
                        this.Courses[i] = new CourseEntityExtensionClass(odataObject.Courses[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.Courses[i] = undefined;
                }
            }
        }

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.TotalCount = odataObject.TotalCount ? parseInt(odataObject.TotalCount, 10) : 0;
    }
}

/**
 * UpdatedCartEntity entity class.
 */
export class UpdatedCartEntityExtensionClass implements IUpdatedCartEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Cart: Entities.Cart;

    // Navigation properties names

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public static cartPropertyName: string = 'Cart';

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        if (odataObject.Cart === null) {
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Cart = undefined;
        } else if (odataObject.Cart['@odata.type'] == null) {
            this.Cart = new EntityClasses.CartClass(odataObject.Cart);
        } else {
            var className: string = odataObject.Cart['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
            if (EntityClasses[className]) {
                this.Cart = new EntityClasses[className](odataObject.Cart);
            }
        }
    }
}

/**
 * AddCourseToCartResponse entity class.
 */
export class AddCourseToCartResponseExtensionClass implements IAddCourseToCartResponse {
    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
    }
}

/**
 * CalculateSurchargeResponseEntity entity class.
 */
export class CalculateSurchargeResponseEntityExtensionClass implements ICalculateSurchargeResponseEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Cart: Entities.Cart;

    // Navigation properties names

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public static cartPropertyName: string = 'Cart';

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        if (odataObject.Cart === null) {
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Cart = undefined;
        } else if (odataObject.Cart['@odata.type'] == null) {
            this.Cart = new EntityClasses.CartClass(odataObject.Cart);
        } else {
            var className: string = odataObject.Cart['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
            if (EntityClasses[className]) {
                this.Cart = new EntityClasses[className](odataObject.Cart);
            }
        }
    }
}

/**
 * CalculateSurchargeResponse entity class.
 */
export class CalculateSurchargeResponseExtensionClass implements ICalculateSurchargeResponse {
    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
    }
}

/**
 * ValidatePoNumberResponse entity class.
 */
export class ValidatePoNumberResponseExtensionClass implements IValidatePoNumberResponse {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Response: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Response = odataObject.Response;
    }
}

/**
 * GetSalesOrderTrackingNumberResponse entity class.
 */
export class GetSalesOrderTrackingNumberResponseExtensionClass implements IGetSalesOrderTrackingNumberResponse {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public TrackingNumber: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.TrackingNumber = odataObject.TrackingNumber;
    }
}

/**
 * GetOrderDetailsAdditionalInformationResponse entity class.
 */
export class GetOrderDetailsAdditionalInformationResponseExtensionClass implements IGetOrderDetailsAdditionalInformationResponse {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ContainerDetails: IContainerDetailsEntity[];

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ErrorMessage: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ContainerDetails = undefined;
        if (odataObject.ContainerDetails) {
            this.ContainerDetails = [];
            for (var i = 0; i < odataObject.ContainerDetails.length; i++) {
                if (odataObject.ContainerDetails[i]) {
                    if (odataObject.ContainerDetails[i]['@odata.type']) {
                        var className: string = odataObject.ContainerDetails[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ContainerDetails[i] = new EntityClasses[className](odataObject.ContainerDetails[i]);
                        }
                    } else {
                        this.ContainerDetails[i] = new ContainerDetailsEntityExtensionClass(odataObject.ContainerDetails[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ContainerDetails[i] = undefined;
                }
            }
        }

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ErrorMessage = odataObject.ErrorMessage;
    }
}

/**
 * GetSalesOrdersadditionalDetailsResponse entity class.
 */
export class GetSalesOrdersadditionalDetailsResponseExtensionClass implements IGetSalesOrdersadditionalDetailsResponse {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public SalesOrderDetails: ISalesOrderAdditionalDetails[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.SalesOrderDetails = undefined;
        if (odataObject.SalesOrderDetails) {
            this.SalesOrderDetails = [];
            for (var i = 0; i < odataObject.SalesOrderDetails.length; i++) {
                if (odataObject.SalesOrderDetails[i]) {
                    if (odataObject.SalesOrderDetails[i]['@odata.type']) {
                        var className: string = odataObject.SalesOrderDetails[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.SalesOrderDetails[i] = new EntityClasses[className](odataObject.SalesOrderDetails[i]);
                        }
                    } else {
                        this.SalesOrderDetails[i] = new SalesOrderAdditionalDetailsExtensionClass(odataObject.SalesOrderDetails[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.SalesOrderDetails[i] = undefined;
                }
            }
        }
    }
}

/**
 * GetGdnInvoiceDetailsResponse entity class.
 */
export class GetGdnInvoiceDetailsResponseExtensionClass implements IGetGdnInvoiceDetailsResponse {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public InvoiceDetails: IGdnInvoiceDetailsResponseEntity;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public InvoiceLineDetails: IGdnInvoiceLineEntity[];

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ShippingContainerInformation: IContainerDetailsEntity[];

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ErrorMessage: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        if (odataObject.InvoiceDetails === null) {
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.InvoiceDetails = undefined;
        } else if (odataObject.InvoiceDetails['@odata.type'] == null) {
            this.InvoiceDetails = new GdnInvoiceDetailsResponseEntityExtensionClass(odataObject.InvoiceDetails);
        } else {
            var className: string = odataObject.InvoiceDetails['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
            if (EntityClasses[className]) {
                this.InvoiceDetails = new EntityClasses[className](odataObject.InvoiceDetails);
            }
        }

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.InvoiceLineDetails = undefined;
        if (odataObject.InvoiceLineDetails) {
            this.InvoiceLineDetails = [];
            for (var i = 0; i < odataObject.InvoiceLineDetails.length; i++) {
                if (odataObject.InvoiceLineDetails[i]) {
                    if (odataObject.InvoiceLineDetails[i]['@odata.type']) {
                        var className: string = odataObject.InvoiceLineDetails[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.InvoiceLineDetails[i] = new EntityClasses[className](odataObject.InvoiceLineDetails[i]);
                        }
                    } else {
                        this.InvoiceLineDetails[i] = new GdnInvoiceLineEntityExtensionClass(odataObject.InvoiceLineDetails[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.InvoiceLineDetails[i] = undefined;
                }
            }
        }

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ShippingContainerInformation = undefined;
        if (odataObject.ShippingContainerInformation) {
            this.ShippingContainerInformation = [];
            for (var i = 0; i < odataObject.ShippingContainerInformation.length; i++) {
                if (odataObject.ShippingContainerInformation[i]) {
                    if (odataObject.ShippingContainerInformation[i]['@odata.type']) {
                        var className: string = odataObject.ShippingContainerInformation[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ShippingContainerInformation[i] = new EntityClasses[className](
                                odataObject.ShippingContainerInformation[i]
                            );
                        }
                    } else {
                        this.ShippingContainerInformation[i] = new ContainerDetailsEntityExtensionClass(
                            odataObject.ShippingContainerInformation[i]
                        );
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ShippingContainerInformation[i] = undefined;
                }
            }
        }

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ErrorMessage = odataObject.ErrorMessage;
    }
}

/**
 * GetBackOrderListResponse entity class.
 */
export class GetBackOrderListResponseExtensionClass implements IGetBackOrderListResponse {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public BackOrderLines: IBackOrderListEntity[];

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public RecordCount: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public AvailableSortOptions: string[];

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public SelectedSort: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public SortOrder: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Filter: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Top: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Skip: number;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.BackOrderLines = undefined;
        if (odataObject.BackOrderLines) {
            this.BackOrderLines = [];
            for (var i = 0; i < odataObject.BackOrderLines.length; i++) {
                if (odataObject.BackOrderLines[i]) {
                    if (odataObject.BackOrderLines[i]['@odata.type']) {
                        var className: string = odataObject.BackOrderLines[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.BackOrderLines[i] = new EntityClasses[className](odataObject.BackOrderLines[i]);
                        }
                    } else {
                        this.BackOrderLines[i] = new BackOrderListEntityExtensionClass(odataObject.BackOrderLines[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.BackOrderLines[i] = undefined;
                }
            }
        }

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.RecordCount = odataObject.RecordCount;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.AvailableSortOptions = undefined;
        if (odataObject.AvailableSortOptions) {
            this.AvailableSortOptions = [];
            for (var i = 0; i < odataObject.AvailableSortOptions.length; i++) {
                if (odataObject.AvailableSortOptions[i]) {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.AvailableSortOptions[i] = odataObject.AvailableSortOptions[i];
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.AvailableSortOptions[i] = undefined;
                }
            }
        }

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.SelectedSort = odataObject.SelectedSort;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.SortOrder = odataObject.SortOrder;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Filter = odataObject.Filter;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Top = odataObject.Top;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Skip = odataObject.Skip;
    }
}

/**
 * ContainerLinesEntity entity class.
 */
export class ContainerLinesEntityExtensionClass implements IContainerLinesEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ItemId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Quantity: number;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ItemId = odataObject.ItemId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Quantity = odataObject.Quantity ? parseFloat(odataObject.Quantity) : 0;
    }
}

/**
 * ContainerDetailsEntity entity class.
 */
export class ContainerDetailsEntityExtensionClass implements IContainerDetailsEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public CarrierService: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ContainerId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ContainerLinesList: IContainerLinesEntity[];

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ModeOfDelivery: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ShippingCarrier: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public TrackingNumber: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Weight: number;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.CarrierService = odataObject.CarrierService;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ContainerId = odataObject.ContainerId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ContainerLinesList = undefined;
        if (odataObject.ContainerLinesList) {
            this.ContainerLinesList = [];
            for (var i = 0; i < odataObject.ContainerLinesList.length; i++) {
                if (odataObject.ContainerLinesList[i]) {
                    if (odataObject.ContainerLinesList[i]['@odata.type']) {
                        var className: string = odataObject.ContainerLinesList[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ContainerLinesList[i] = new EntityClasses[className](odataObject.ContainerLinesList[i]);
                        }
                    } else {
                        this.ContainerLinesList[i] = new ContainerLinesEntityExtensionClass(odataObject.ContainerLinesList[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ContainerLinesList[i] = undefined;
                }
            }
        }

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ModeOfDelivery = odataObject.ModeOfDelivery;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ShippingCarrier = odataObject.ShippingCarrier;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.TrackingNumber = odataObject.TrackingNumber;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Weight = odataObject.Weight ? parseFloat(odataObject.Weight) : 0;
    }
}

/**
 * SalesOrderAdditionalDetails entity class.
 */
export class SalesOrderAdditionalDetailsExtensionClass implements ISalesOrderAdditionalDetails {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public SalesId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public WebTrackingId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public PoNumber: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.SalesId = odataObject.SalesId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.WebTrackingId = odataObject.WebTrackingId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.PoNumber = odataObject.PoNumber;
    }
}

/**
 * GdnFreightDetails entity class.
 */
export class GdnFreightDetailsExtensionClass implements IGdnFreightDetails {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public FreightCode: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public FreightDescription: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public FreightAmount: number;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.FreightCode = odataObject.FreightCode;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.FreightDescription = odataObject.FreightDescription;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.FreightAmount = odataObject.FreightAmount ? parseFloat(odataObject.FreightAmount) : 0;
    }
}

/**
 * GdnInvoiceDetailsResponseEntity entity class.
 */
export class GdnInvoiceDetailsResponseEntityExtensionClass implements IGdnInvoiceDetailsResponseEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public SalesOrderNumber: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public CustomerAccount: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public InvoiceNumber: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public PoNumber: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public DeliveryName: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public DeliveryLocation: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public DeliveryAddress: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public InvoiceLocation: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public InvoiceAddress: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public InvoiceDate: Date;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ShipVia: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public OrderDate: Date;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public FreightCode: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public FreightDescription: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public FreightAmount: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public BillToAccount: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public TotalPieces: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public SubTotal: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Tax: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public InvoiceAmount: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public InvoiceAmountMst: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public AmountDue: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public DueDate: Date;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Terms: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public FreightList: IGdnFreightDetails[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.SalesOrderNumber = odataObject.SalesOrderNumber;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.CustomerAccount = odataObject.CustomerAccount;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.InvoiceNumber = odataObject.InvoiceNumber;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.PoNumber = odataObject.PoNumber;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DeliveryName = odataObject.DeliveryName;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DeliveryLocation = odataObject.DeliveryLocation;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DeliveryAddress = odataObject.DeliveryAddress;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.InvoiceLocation = odataObject.InvoiceLocation;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.InvoiceAddress = odataObject.InvoiceAddress;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.InvoiceDate =
            odataObject.InvoiceDate instanceof Date
                ? odataObject.InvoiceDate
                : odataObject.InvoiceDate
                ? jsonLightReadStringPropertyValue(odataObject.InvoiceDate, 'Edm.DateTimeOffset', false)
                : undefined;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ShipVia = odataObject.ShipVia;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.OrderDate =
            odataObject.OrderDate instanceof Date
                ? odataObject.OrderDate
                : odataObject.OrderDate
                ? jsonLightReadStringPropertyValue(odataObject.OrderDate, 'Edm.DateTimeOffset', false)
                : undefined;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.FreightCode = odataObject.FreightCode;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.FreightDescription = odataObject.FreightDescription;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.FreightAmount = odataObject.FreightAmount ? parseFloat(odataObject.FreightAmount) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.BillToAccount = odataObject.BillToAccount;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.TotalPieces = odataObject.TotalPieces ? parseFloat(odataObject.TotalPieces) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.SubTotal = odataObject.SubTotal ? parseFloat(odataObject.SubTotal) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Tax = odataObject.Tax ? parseFloat(odataObject.Tax) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.InvoiceAmount = odataObject.InvoiceAmount ? parseFloat(odataObject.InvoiceAmount) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.InvoiceAmountMst = odataObject.InvoiceAmountMst ? parseFloat(odataObject.InvoiceAmountMst) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.AmountDue = odataObject.AmountDue ? parseFloat(odataObject.AmountDue) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DueDate =
            odataObject.DueDate instanceof Date
                ? odataObject.DueDate
                : odataObject.DueDate
                ? jsonLightReadStringPropertyValue(odataObject.DueDate, 'Edm.DateTimeOffset', false)
                : undefined;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Terms = odataObject.Terms;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.FreightList = undefined;
        if (odataObject.FreightList) {
            this.FreightList = [];
            for (var i = 0; i < odataObject.FreightList.length; i++) {
                if (odataObject.FreightList[i]) {
                    if (odataObject.FreightList[i]['@odata.type']) {
                        var className: string = odataObject.FreightList[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.FreightList[i] = new EntityClasses[className](odataObject.FreightList[i]);
                        }
                    } else {
                        this.FreightList[i] = new GdnFreightDetailsExtensionClass(odataObject.FreightList[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.FreightList[i] = undefined;
                }
            }
        }
    }
}

/**
 * GdnInvoiceLineEntity entity class.
 */
export class GdnInvoiceLineEntityExtensionClass implements IGdnInvoiceLineEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ItemRecId: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ItemNumber: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ProductId: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public CustItemNumber: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Description: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public AltDescription: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public CurrencyCode: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Quantity: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ListPrice: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public UnitPrice: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Total: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public LineNum: number;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ItemRecId = odataObject.ItemRecId ? parseInt(odataObject.ItemRecId, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ItemNumber = odataObject.ItemNumber;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ProductId = odataObject.ProductId ? parseInt(odataObject.ProductId, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.CustItemNumber = odataObject.CustItemNumber;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Description = odataObject.Description;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.AltDescription = odataObject.AltDescription;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.CurrencyCode = odataObject.CurrencyCode;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Quantity = odataObject.Quantity ? parseFloat(odataObject.Quantity) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ListPrice = odataObject.ListPrice ? parseFloat(odataObject.ListPrice) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.UnitPrice = odataObject.UnitPrice ? parseFloat(odataObject.UnitPrice) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Total = odataObject.Total ? parseFloat(odataObject.Total) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.LineNum = odataObject.LineNum ? parseFloat(odataObject.LineNum) : 0;
    }
}

/**
 * BackOrderListEntity entity class.
 */
export class BackOrderListEntityExtensionClass implements IBackOrderListEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public SalesId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public PurchaseOrderNumber: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ManufacturerNumber: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ItemId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Quantity: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Total: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ShippingDate: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public DeliveryType: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.SalesId = odataObject.SalesId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.PurchaseOrderNumber = odataObject.PurchaseOrderNumber;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ManufacturerNumber = odataObject.ManufacturerNumber;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ItemId = odataObject.ItemId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Quantity = odataObject.Quantity;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Total = odataObject.Total ? parseFloat(odataObject.Total) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ShippingDate = odataObject.ShippingDate;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DeliveryType = odataObject.DeliveryType;
    }
}

/**
 * SalesReportResponseEntity entity class.
 */
export class SalesReportResponseEntityExtensionClass implements ISalesReportResponseEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public DidSalesReportComplete: boolean;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public SalesReportMessage: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DidSalesReportComplete = odataObject.DidSalesReportComplete;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.SalesReportMessage = odataObject.SalesReportMessage;
    }
}

/**
 * PriceByManufacturerResponseEntity entity class.
 */
export class PriceByManufacturerResponseEntityExtensionClass implements IPriceByManufacturerResponseEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public DidPriceByManufacturerComplete: boolean;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public PriceByManufacturerMessage: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DidPriceByManufacturerComplete = odataObject.DidPriceByManufacturerComplete;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.PriceByManufacturerMessage = odataObject.PriceByManufacturerMessage;
    }
}

/**
 * DealerLocationEntity entity class.
 */
export class DealerLocationEntityExtensionClass implements IDealerLocationEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Name: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public CustomerNumber: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public RecId: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Address: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ZipCode: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Latitude: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Longitude: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Distance: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Name = odataObject.Name;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.CustomerNumber = odataObject.CustomerNumber;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.RecId = odataObject.RecId ? parseInt(odataObject.RecId, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Address = odataObject.Address;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ZipCode = odataObject.ZipCode;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Latitude = odataObject.Latitude ? parseFloat(odataObject.Latitude) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Longitude = odataObject.Longitude ? parseFloat(odataObject.Longitude) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Distance = odataObject.Distance ? parseFloat(odataObject.Distance) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * DealerEntity entity class.
 */
export class DealerEntityExtensionClass implements IDealerEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public AccountNum: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Name: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public RecId: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Address: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ManufacturerCode: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ManufacturerName: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ContractCode: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ContractDescription: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.AccountNum = odataObject.AccountNum;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Name = odataObject.Name;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.RecId = odataObject.RecId ? parseInt(odataObject.RecId, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Address = odataObject.Address;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ManufacturerCode = odataObject.ManufacturerCode;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ManufacturerName = odataObject.ManufacturerName;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ContractCode = odataObject.ContractCode;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ContractDescription = odataObject.ContractDescription;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * EligibilityItem entity class.
 */
export class EligibilityItemExtensionClass implements IEligibilityItem {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ItemId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public IsEligible: boolean;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public IsAssorted: boolean;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ItemId = odataObject.ItemId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.IsEligible = odataObject.IsEligible;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.IsAssorted = odataObject.IsAssorted;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * SaveCartPromotionsResponseRS entity class.
 */
export class SaveCartPromotionsResponseRSExtensionClass implements ISaveCartPromotionsResponseRS {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Status: boolean;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Message: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Status = odataObject.Status;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Message = odataObject.Message;
    }
}

/**
 * DeleteCartPromotionsByOrderTemplateIdResponseRS entity class.
 */
export class DeleteCartPromotionsByOrderTemplateIdResponseRSExtensionClass implements IDeleteCartPromotionsByOrderTemplateIdResponseRS {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Status: boolean;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Message: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Status = odataObject.Status;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Message = odataObject.Message;
    }
}

/**
 * WhereClause entity class.
 */
export class WhereClauseExtensionClass implements IWhereClause {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Column: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Operator: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Value: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public IsString: boolean;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Conjunction: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Column = odataObject.Column;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Operator = odataObject.Operator;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Value = odataObject.Value;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.IsString = odataObject.IsString;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Conjunction = odataObject.Conjunction;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * TableQueryResponseEntity entity class.
 */
export class TableQueryResponseEntityExtensionClass implements ITableQueryResponseEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public JsonResults: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Count: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public IsError: boolean;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.JsonResults = odataObject.JsonResults;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Count = odataObject.Count ? parseInt(odataObject.Count, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.IsError = odataObject.IsError;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * ItemInventoryLevelProfileEntity entity class.
 */
export class ItemInventoryLevelProfileEntityExtensionClass implements IItemInventoryLevelProfileEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ItemId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public InventoryLevelProfile: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Code: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Label: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public UpperBoundInclusive: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ItemId = odataObject.ItemId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.InventoryLevelProfile = odataObject.InventoryLevelProfile;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Code = odataObject.Code;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Label = odataObject.Label;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.UpperBoundInclusive = odataObject.UpperBoundInclusive ? parseFloat(odataObject.UpperBoundInclusive) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * ProfileDataMap entity class.
 */
export class ProfileDataMapExtensionClass implements IProfileDataMap {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Profile: IItemInventoryLevelProfileEntity[];

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Products: string[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Profile = undefined;
        if (odataObject.Profile) {
            this.Profile = [];
            for (var i = 0; i < odataObject.Profile.length; i++) {
                if (odataObject.Profile[i]) {
                    if (odataObject.Profile[i]['@odata.type']) {
                        var className: string = odataObject.Profile[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.Profile[i] = new EntityClasses[className](odataObject.Profile[i]);
                        }
                    } else {
                        this.Profile[i] = new ItemInventoryLevelProfileEntityExtensionClass(odataObject.Profile[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.Profile[i] = undefined;
                }
            }
        }

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Products = undefined;
        if (odataObject.Products) {
            this.Products = [];
            for (var i = 0; i < odataObject.Products.length; i++) {
                if (odataObject.Products[i]) {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.Products[i] = odataObject.Products[i];
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.Products[i] = undefined;
                }
            }
        }
    }
}

/**
 * InvoicePaymentScheduleEntity entity class.
 */
export class InvoicePaymentScheduleEntityExtensionClass implements IInvoicePaymentScheduleEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Invoice: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public AmountCur: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public DueDate: Date;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public AllowableFreightCharge: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Description: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public SalesId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public PurchaseOrder: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Invoice = odataObject.Invoice;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.AmountCur = odataObject.AmountCur ? parseFloat(odataObject.AmountCur) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DueDate =
            odataObject.DueDate instanceof Date
                ? odataObject.DueDate
                : odataObject.DueDate
                ? jsonLightReadStringPropertyValue(odataObject.DueDate, 'Edm.DateTimeOffset', false)
                : undefined;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.AllowableFreightCharge = odataObject.AllowableFreightCharge ? parseFloat(odataObject.AllowableFreightCharge) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Description = odataObject.Description;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.SalesId = odataObject.SalesId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.PurchaseOrder = odataObject.PurchaseOrder;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * SearchCustomerByAccountIdResponse entity class.
 */
export class SearchCustomerByAccountIdResponseExtensionClass implements ISearchCustomerByAccountIdResponse {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public BusinessPartnerId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public AccountNumber: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.BusinessPartnerId = odataObject.BusinessPartnerId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.AccountNumber = odataObject.AccountNumber;
    }
}

/**
 * InvoiceAmountEntity entity class.
 */
export class InvoiceAmountEntityExtensionClass implements IInvoiceAmountEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public InvoiceId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Amount: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.InvoiceId = odataObject.InvoiceId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Amount = odataObject.Amount ? parseFloat(odataObject.Amount) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * AddInvoicesWithAmountsToCartResponse entity class.
 */
export class AddInvoicesWithAmountsToCartResponseExtensionClass implements IAddInvoicesWithAmountsToCartResponse {
    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
    }
}

/**
 * ProductCodeEntity entity class.
 */
export class ProductCodeEntityExtensionClass implements IProductCodeEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ItemId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ProductCode: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ItemId = odataObject.ItemId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ProductCode = odataObject.ProductCode;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * GetProductCodesResponse entity class.
 */
export class GetProductCodesResponseExtensionClass implements IGetProductCodesResponse {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ProductCodes: IProductCodeEntity[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ProductCodes = undefined;
        if (odataObject.ProductCodes) {
            this.ProductCodes = [];
            for (var i = 0; i < odataObject.ProductCodes.length; i++) {
                if (odataObject.ProductCodes[i]) {
                    if (odataObject.ProductCodes[i]['@odata.type']) {
                        var className: string = odataObject.ProductCodes[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ProductCodes[i] = new EntityClasses[className](odataObject.ProductCodes[i]);
                        }
                    } else {
                        this.ProductCodes[i] = new ProductCodeEntityExtensionClass(odataObject.ProductCodes[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ProductCodes[i] = undefined;
                }
            }
        }
    }
}
